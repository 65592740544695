import React from "react";
import classNames from "classnames";
import Logo from "../Logo";
import useScrollPosition from "../../utils/useScrollPosition";
import SubscribeButton from "../SubscribeButton";
import styles from "./Topbar.module.scss";

export default function Topbar() {
    const scrollY = useScrollPosition();
    const scrolledMode = scrollY > 100;

    return (
        <nav className={classNames(styles.topbar, scrolledMode && styles.topbarScrolled)}>
            <Logo className={styles.logo} />
            <SubscribeButton className={styles.subscribeButton} />
        </nav>
    );
}
