import React from "react";
import Section from "../../../../components/Section";
import SubscribeButton from "../../../../components/SubscribeButton";
import { useTranslation } from "../../../../utils/i18n";
import styles from "./GetStarted.module.scss";

export default function GetStarted() {
    const { t } = useTranslation("features");

    return (
        <Section className={styles.getStarted}>
            <div>{t("freeTime")}</div>
            <br />
            <div className={styles.buttonContainer}>
                <SubscribeButton />
            </div>
        </Section>
    );
}
