import React from "react";
import Section from "../../../../components/Section";
import styles from "./Features.module.scss";
import { useTranslation } from "../../../../utils/i18n";
import formImage from "../../../../assets/images/form.png";
import questionsImage from "../../../../assets/images/questions.png";
import contractImage from "../../../../assets/images/contract.png";

function Feature({ image, heading, children }) {
    return (
        <div className={styles.feature}>
            <div className={styles.featureContent}>
                <div className={styles.featureImageWrapper}>
                    <div className={styles.featureImage} style={{ backgroundImage: `url(${image})` }} />
                </div>
                <div className={styles.featureDescription}>
                    <h3>{heading}</h3>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default function Features() {
    const { t } = useTranslation("features");

    return (
        <Section className={styles.features}>
            <h1>{t("heading")}</h1>

            <Feature image={questionsImage} heading={t("questions.heading")}>
                {t("questions.line1")}
            </Feature>
            <Feature image={formImage} heading={t("forms.heading")}>
                {t("forms.line1")}
            </Feature>
            <Feature image={contractImage} heading={t("contracts.heading")}>
                {t("contracts.line1")}
            </Feature>
        </Section>
    );
}
