import React from "react";
import Section from "../../../../components/Section";
import SubscribeButton from "../../../../components/SubscribeButton";
import OverviewCarousel from "./OverviewCarousel";
import styles from "./Heading.module.scss";
import { useTranslation } from "../../../../utils/i18n";

export default function Heading() {
    const { t } = useTranslation("heading");

    return (
        <Section className={styles.heading}>
            <div className={styles.content}>
                <h1>
                    {t("1")}
                    <br />
                    {t("2")} <span className={styles.pro}>{t("3")}</span>
                </h1>
                <OverviewCarousel />
                <br />
                <div className={styles.subscribeButton}>
                    <SubscribeButton />
                </div>
            </div>
            <div className={styles.image} />
        </Section>
    );
}
