import i18n from "i18next";
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import pl from "../assets/translations/pl.json";
import en from "../assets/translations/en.json";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            pl,
            en,
        },
        lng: "pl", // if you're using a language detector, do not define the lng option
        fallbackLng: "pl",

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

export { Trans };
export { useTranslation };
