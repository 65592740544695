import React from "react";
import Section from "../../../../components/Section";
import styles from "./Footer.module.scss";

export default function Footer() {
    return (
        <Section className={styles.footer}>
            <p>ECHITECT © BY SPRINTIE SP. Z O.O. ALL RIGHTS RESERVED</p>
            <p>MADE WITH ❤️ IN POLAND</p>
        </Section>
    );
}
