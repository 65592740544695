import React from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

export default function Button({ className, onClick, children }) {
    return (
        <button type="text" className={classNames(styles.button, className)} onClick={onClick}>
            {children}
        </button>
    );
}
