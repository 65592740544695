import React from "react";
import classnames from "classnames";
import Icon, { mdiClipboardMultipleOutline } from "../Icon";
import { useTranslation } from "../../utils/i18n";
import styles from "./Logo.module.scss";

export default function Logo({ className }) {
    const { t } = useTranslation("topbar");

    return (
        <div className={classnames(className)}>
            <div className={styles.iconAndName}>
                <Icon path={mdiClipboardMultipleOutline} className={styles.icon} size="1em" />
                <span className={styles.name}>echitect</span>
            </div>
            <div className={styles.description}>{t("productDescription")}</div>
        </div>
    );
}
