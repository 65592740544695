import Topbar from "./components/Topbar";
import MainPage from "./pages/Main";

function App() {
    return (
        <div>
            <Topbar />
            <MainPage />
        </div>
    );
}

export default App;
