import React, { cloneElement, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Carousel.module.scss";

export function Frame({ position, children }) {
    return (
        <div
            className={styles.frame}
            style={{
                left: `${position * 100}%`,
            }}
        >
            {children}
        </div>
    );
}

const CHANGE_TIME_MS = 3000;

export default function Carousel({ className, children }) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setActiveIndex((activeIndex + 1) % children.length);
        }, CHANGE_TIME_MS);

        return () => clearInterval(timeout);
    }, [activeIndex, children.length]);

    return (
        <div className={classNames(styles.carousel, className)}>
            <div className={styles.frames}>
                {React.Children.map(children, (child, index) =>
                    cloneElement(child, {
                        position: index - activeIndex,
                    })
                )}
            </div>
            <div className={styles.itemsNav}>
                {React.Children.map(children, (child, index) => (
                    <div className={classNames(styles.navItem, index === activeIndex && styles.navItemActive)} />
                ))}
            </div>
        </div>
    );
}
