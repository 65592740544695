import React from "react";
import Button from "../Button";
import { useTranslation } from "../../utils/i18n";
import styles from "./SubscribeButton.module.scss";

export default function SubscribeButton({ className }) {
    const { t } = useTranslation("subscription");

    return (
        // eslint-disable-next-line no-undef
        <Button {...{ className }} onClick={() => ml_webform_5125802("show")}>
            <div className={styles.title}>{t("subscribe")}</div>
            <div className={styles.subtitle}>{t("subscribeDetailed")}</div>
        </Button>
    );
}
