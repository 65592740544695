import React from "react";
import HeadingSection from "./sections/Heading";
import ForWhoSection from "./sections/ForWho";
import FeaturesSection from "./sections/Features";
import GetStartedSection from "./sections/GetStarted";
import FooterSection from "./sections/Footer";

export default function Main() {
    return (
        <>
            <HeadingSection />
            <ForWhoSection />
            <FeaturesSection />
            <GetStartedSection />
            <FooterSection />
        </>
    );
}
