import React from "react";
import Carousel, { Frame } from "../../../../components/Carousel";
import styles from "./OverviewCarousel.module.scss";
import formImage from "../../../../assets/images/form.png";
import questionsImage from "../../../../assets/images/questions.png";
import contractImage from "../../../../assets/images/contract.png";
import { useTranslation } from "../../../../utils/i18n";

function OverviewFrame({ image, children, ...otherProps }) {
    return (
        <Frame {...otherProps}>
            <div className={styles.frame}>
                <div className={styles.frameImage} style={{ backgroundImage: `url(${image})` }} />
                <div className={styles.frameText}>{children}</div>
            </div>
        </Frame>
    );
}

export default function OverviewCarousel() {
    const { t } = useTranslation("headingCarousel");

    return (
        <Carousel className={styles.carousel}>
            <OverviewFrame image={questionsImage}>{t("questions")}</OverviewFrame>
            <OverviewFrame image={formImage}>{t("forms")}</OverviewFrame>
            <OverviewFrame image={contractImage}>{t("contracts")}</OverviewFrame>
        </Carousel>
    );
}
