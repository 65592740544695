import React from "react";
import Section from "../../../../components/Section";
import styles from "./ForWho.module.scss";
import { useTranslation } from "../../../../utils/i18n";

export default function Features() {
    const { t } = useTranslation("forWho");

    return (
        <Section className={styles.features}>
            <h1>{t("heading")}</h1>
            {t("whoExplained")}
        </Section>
    );
}
